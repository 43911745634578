<template>
  <el-dialog
    v-model="showAddAssetsDialog"
    width="440px"
    align-center
    :destroy-on-close="true"
    :show-close="true"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    class="elv-delete-tip-add-assets-dialog"
    @close="onCloseDialog"
  >
    <template #header="{ titleId }">
      <div class="elv-add-tip-assets-dialog-header-title">
        <h4 :id="titleId">
          {{ isEditAssets ? t('common.editAsset') : t('common.addAsset') }}
        </h4>
      </div>
    </template>
    <div v-if="isAddStandAssets" class="elv-add-assets-dialog-content">
      <p class="content-title">{{ currentCurrency ? t('title.assetName') : t('title.searchByName') }}</p>
      <ElvSelect
        v-model="currentCurrency"
        :teleported="false"
        filterable
        remote
        clearable
        remote-show-suffix
        reserve-keyword
        :loading="searchLoading"
        :is-show-suffix-info="true"
        width="400px"
        :remote-method="remoteCurrencyMethod"
        :placeholder="t('common.input')"
        :options="currenciesData"
        @change="onChangeCurrency()"
        @visible-change="changeSelectPopover"
      >
        <template #footerCustomContent>
          <div v-if="!searchLoading && currenciesData.length" class="add-custom-assets" @click="addCustomAssets">
            <SvgIcon name="source-add" width="14" height="14" fill="#b3bfce" />
            <span>{{ t('message.addViaCOntractAddress') }}</span>
          </div>
        </template>
      </ElvSelect>
      <p class="content-title">{{ t('report.priceProvider') }}</p>
      <ElvSelect
        v-model="priceProviderId"
        :teleported="false"
        remote
        clearable
        filterable
        remote-show-suffix
        reserve-keyword
        :is-show-suffix-info="true"
        width="400px"
        class="elv-add-assets-dialog-content-price-provider-select"
        popper-class="elv-add-assets-dialog-content-price-provider-popper"
        :placeholder="t('common.select')"
        :options="priceProviderList"
      >
      </ElvSelect>
      <p v-if="currentCurrency && smartContractList.length" class="content-title">{{ t('title.smartContract') }}</p>
      <div v-if="currentCurrency && smartContractList.length" class="content-contract-content">
        <p v-for="(item, index) in smartContractList" :key="index + item">
          <img :src="item.platform.logo" alt="" />
          <span class="contract-smart">{{ item.contract_address }}</span>
          <SvgIcon
            class="sources-copy"
            name="sources-copy"
            width="12"
            height="12"
            @click="onClickedCopy(item.contract_address)"
          />
        </p>
      </div>
      <!-- <div v-if="currentCurrency && !smartContractList.length" class="content-contract-content-empty">
        {{ t('message.noContractAddress') }}
      </div> -->
      <i18n-t
        v-if="isAddStandAssets && submitErrorInfo?.code === errorCode.standExist"
        keypath="valuation.assets.assetAlreadyInList"
        tag="p"
        class="elv-add-contract-waring-info"
        scope="global"
      >
        <template #address>
          <span>{{ standCheckCurrencyInfo }}</span>
        </template>
      </i18n-t>
    </div>
    <div v-else class="elv-add-assets-dialog-content">
      <p class="content-title">{{ t('title.assetName') }}</p>
      <el-input v-model="customAddParams.assetName" :placeholder="t('placeholder.inputAssetName')" />
      <p class="content-title">{{ t('title.symbolCode') }}</p>
      <el-input v-model="customAddParams.symbolCode" :placeholder="t('placeholder.inputSymbolCode')" />
      <p class="content-title">{{ t('report.priceProvider') }}</p>
      <ElvSelect
        v-model="customAddParams.priceProviderId"
        :teleported="false"
        remote
        filterable
        remote-show-suffix
        reserve-keyword
        :disabled="isDisabledCheckPriceProvider"
        :is-show-suffix-info="true"
        width="400px"
        class="elv-add-assets-dialog-content-price-provider-select"
        popper-class="elv-add-assets-dialog-content-price-provider-popper"
        :placeholder="t('common.select')"
        :options="priceProviderList"
        @change="changePriceProviderSelect"
      />
      <p class="content-title">{{ t('title.assetLogo') }}</p>
      <el-upload
        ref="uploadLogoRef"
        v-loading="uploadLoading"
        class="leger-asset-add-upload-log"
        :show-file-list="false"
        :headers="{ Authorization: authorization }"
        :action="`${VITE_API_URL}/entity/${entityId}/asset/upload`"
        accept="image/*"
        :limit="1"
        :on-exceed="handleExceed"
        :before-upload="beforeUploadAssetLogo"
        @error="onUploadError"
        @success="onUploadSuccess"
      >
        <div v-if="!customAddParams.logo" class="default-submit-icon">
          <SvgIcon name="submit-icon" width="30" height="30" />
        </div>
        <div v-else class="leger-asset-add-upload-log-icon-content">
          <img :src="currentSubmitLogo" alt="" />
        </div>
      </el-upload>
      <p class="content-title">{{ t('title.smartContract') }}</p>
      <div ref="customContract" class="contract-content">
        <div
          v-for="(item, index) in customAddParams.contractList"
          :key="item.platformId + index"
          class="contract-content-item"
        >
          <div class="contract-content-item-check-content">
            <ElvSelect
              v-model="item.platformId"
              filterable
              remote
              remote-show-suffix
              reserve-keyword
              :disabled="item.isDisabled"
              :loading="searchLoading"
              width="150px"
              :remote-method="filterWantPlattList()"
              :placeholder="t('placeholder.search')"
              :options="platFormOptions"
              @change="changeSelectPlatform(item, index)"
            />
            <el-input
              v-model.trim="item.address"
              class="contract-content-item-address"
              :disabled="item.isDisabled"
              :placeholder="t('placeholder.inputContractAddress')"
            />
            <el-input-number
              v-model="item.decimal"
              class="contract-content-item-decimal"
              :value-on-clear="null"
              :controls="false"
              :disabled="item.isDisabled"
              :placeholder="t('title.decimal')"
            />
            <SvgIcon
              name="del"
              width="16"
              height="16"
              :class="{ 'elv-contract-item-delete-disabled': item.isDisabled }"
              @click="onClickedDeleteContract(index)"
            />
          </div>
          <i18n-t
            v-if="item.errorType !== -1 && item.errorType === errorCode.assetExist"
            keypath="valuation.assets.contractAddressAlreadyIn"
            tag="p"
            class="elv-add-contract-waring-info"
            scope="global"
          >
            <template #address>
              <span>{{ platformTypeInfo?.platform?.name || '' }}</span>
            </template>
          </i18n-t>
          <i18n-t
            v-if="item.errorType !== -1 && item.errorType === errorCode.addressExist"
            keypath="valuation.assets.contractAddressBelongsIn"
            tag="p"
            class="elv-add-contract-waring-info"
            scope="global"
          >
            <template #address>
              <span>{{ platformTypeInfo?.underlyingcurrency?.name || '' }}</span>
            </template>
            <template #addresslink>
              <span class="link" @click="ChangeBelongAssetAddress">{{
                platformTypeInfo?.underlyingcurrency?.name || ''
              }}</span>
            </template>
          </i18n-t>
        </div>
      </div>
      <elv-button
        height="32"
        width="118"
        plain
        type="primary"
        class="add-contract-button"
        :disabled="isCantAddContractItem"
        @click="onClickAddNewContract"
      >
        <SvgIcon :name="isCantAddContractItem ? 'add-contract-disabled' : 'add-contract'" width="16" height="16" />{{
          t('title.addContract')
        }}</elv-button
      >
    </div>
    <template #footer>
      <div class="elv-add-tip-assets-dialog-footer-content">
        <el-button
          type="primary"
          round
          :loading="isAddLoading"
          class="elv-add-assets-dialog-footer__confirm"
          :class="{ 'elv-Account-delete-dialog-footer__cancel': isUnEnableAdd }"
          @click="onClickConfirmSaveAssetInfo"
        >
          {{ isEditAssets ? t('button.edit') : t('button.add') }}</el-button
        >
      </div>
    </template>
  </el-dialog>
  <ChangeAssetPriceProviderDialog
    ref="changeAssetPriceProviderDialogRef"
    @onConfirmChangeAssetPriceProvider="onConfirmChangeAssetPriceProvider"
    @onCancelChangeAssetPriceProvider="onCancelChangeAssetPriceProvider"
  />
</template>

<script setup lang="ts">
import ConfigApi from '@/api/ConfigApi'
import useClipboard from 'vue-clipboard3'
import { useCookies } from 'vue3-cookies'
import ValuationApi from '@/api/ValuationApi'
import { ElMessage, genFileId } from 'element-plus'
import { cloneDeep, isEmpty, trim } from 'lodash-es'
import ElvSelect from '@/components/Base/ElvSelect.vue'
import { PriceProviderItemType } from '#/ValuationTypes'
import { useEntityStore } from '@/stores/modules/entity'
import type { UploadProps, UploadRawFile } from 'element-plus'
import ChangeAssetPriceProviderDialog from './ChangeAssetPriceProviderDialog.vue'

type OptionType = {
  id?: string
  value: string
  label: string
  icon?: string
  alt?: string
  isCheck?: boolean
  isDeleted?: boolean
  suffixName?: string
  formatDetailPlatformsList?: []
}

type platformType = {
  id: string
  value: string
  label: string
  icon: string
  alt: string
  isDisabled?: boolean
}

type submitContractType = {
  platformId: string
  address: string
  decimal: number | undefined
  errorType?: number
  isDisabled?: boolean
}

const props = defineProps({
  priceProviderOriginList: {
    type: Array<PriceProviderItemType>,
    default: () => []
  }
})

const emit = defineEmits(['onClickConfirmHandler'])

const defaultLogo = 'https://static.elven.com/asset/default-asset.png'

const errorCode = Object.freeze({
  assetExist: 10000,
  addressExist: 10001,
  standExist: 34000
})

const { VITE_API_URL } = import.meta.env

const { t } = useI18n()
const { cookies } = useCookies()
const entityStore = useEntityStore()
const { toClipboard } = useClipboard()

const customContract = ref()
const currentCurrency = ref('')
const priceProviderId = ref('')
const customBeforeChangePriceProvider = ref('')
const uploadLogoRef = useTemplateRef('uploadLogoRef')
const changeAssetPriceProviderDialogRef = useTemplateRef('changeAssetPriceProviderDialogRef')

const isEditAssets = ref(false)
const isAddLoading = ref(false)
const searchLoading = ref(false)
const uploadLoading = ref(false)
const isAddStandAssets = ref(true)
const showAddAssetsDialog = ref(false)

const editData = ref<any>({})
const submitErrorInfo = ref<any>({})
const currentCurrencyData = ref<OptionType>()
const smartContractList = ref<Array<any>>([])
const platFormOptions = ref<platformType[]>([])
const currenciesData = ref<Array<OptionType>>([])
const recommendCurrenciesData = ref<Array<OptionType>>([])

const customAddParams = reactive({
  assetName: '',
  symbolCode: '',
  logo: defaultLogo,
  priceProviderId: '0',
  contractList: [] as Array<submitContractType>
})

const authorization = computed(() => {
  return `Bearer ${cookies.get('elv-app-token')}`
})

const entityId = computed(() => {
  return String(entityStore.entityDetail?.entityId)
})

const allChainList = computed(() => {
  return entityStore.chainList || []
})

const standCheckCurrencyInfo = computed(() => {
  if (!currentCurrency.value) return ''
  const currency = currenciesData.value.find((item) => item.value === currentCurrency.value)
  return currency?.label || ''
})

const priceProviderList = computed(() => {
  const notSpecial = {
    id: '0',
    value: '0',
    label: 'Not Specified',
    suffixName: t('valuation.price.addWithOutPrice'),
    alt: ''
  }
  const currentList = props.priceProviderOriginList.map((item) => {
    let suffixShowName = ''
    if (isAddStandAssets.value) {
      if (currentCurrencyData.value?.suffixName) {
        suffixShowName =
          item.userCurrencyList?.some((currencyItem) => currencyItem === currentCurrencyData.value?.suffixName) ||
          item.type === 'API_COINGECKO'
            ? ''
            : t('valuation.price.notCovered')
      }
    } else {
      suffixShowName =
        customAddParams.symbolCode &&
        !item.userCurrencyList?.some((currencyItem) => currencyItem === customAddParams.symbolCode)
          ? t('valuation.price.notCovered')
          : ''
    }
    return {
      id: `${item.priceProviderId}`,
      value: `${item.priceProviderId}`,
      label: `${item.name} ${item?.isDefault ? `(${t('common.default')})` : ''}`,
      suffixName: suffixShowName,
      alt: item.type || ''
    }
  })
  const index = currentList.findIndex((item) => item.alt === 'API_COINGECKO')
  const [templatePriceProvider] = currentList.splice(index, 1)
  if (isAddStandAssets.value) {
    currentList.push(notSpecial)
    if (templatePriceProvider) {
      currentList.unshift(templatePriceProvider)
    }
  } else {
    currentList.unshift(notSpecial)
    // if (templatePriceProvider) {
    //   currentList.push(templatePriceProvider)
    // }
  }

  return currentList
})

const isDisabledCheckPriceProvider = computed(() => {
  const currentPriceProvider = priceProviderList.value.find((item) => item.value === customAddParams.priceProviderId)
  if (!currentPriceProvider || !isEditAssets.value) return false
  if (
    (currentPriceProvider.suffixName === '' && currentPriceProvider.value !== '0') ||
    currentPriceProvider.alt === 'API_COINGECKO'
  ) {
    return true
  }
  return false
})

const currentSubmitLogo = computed(() => {
  return customAddParams.logo
})

const platformTypeInfo = computed(() => {
  return submitErrorInfo.value?.extraData
})

const isCantAddContractItem = computed(() => {
  return (
    customAddParams?.contractList?.length > 0 &&
    customAddParams.contractList.some((item) => !item.platformId || !item.address || !item.decimal)
  )
})

const isUnEnableAdd = computed(() => {
  if (isAddStandAssets.value) {
    return !currentCurrency.value || searchLoading.value || !isEmpty(submitErrorInfo.value) || !priceProviderId.value
  }
  return (
    !customAddParams.assetName ||
    !customAddParams.symbolCode ||
    !customAddParams.contractList.length ||
    isCantAddContractItem.value ||
    !customAddParams.priceProviderId ||
    !isEmpty(submitErrorInfo.value)
  )
})

/**
 * 获取推荐币种
 * @param params 资产参数
 */
const getRecommendCurrency = async (params: any) => {
  const searchList = await ConfigApi.searchCurrencyList(params)
  currenciesData.value = searchList.data.map((item) => ({
    id: item.underlyingCurrencyId,
    value: item.underlyingCurrencyId,
    label: item.name,
    suffixName: item.showSymbol,
    icon: item.logo,
    alt: item.logo || '',
    formatDetailPlatformsList: item?.formatDetailPlatforms || [],
    isCheck: false
  }))
  if (params?.recommend) {
    recommendCurrenciesData.value = cloneDeep(currenciesData.value)
  }
}

/**
 * 切换价格源，弹出价格源确认弹窗
 */
const changePriceProviderSelect = () => {
  if (!isEditAssets.value || !customAddParams.priceProviderId) return
  changeAssetPriceProviderDialogRef.value?.changeAssetPriceProviderDialogStatus()
}

/**
 * 确认选择所选择价格源
 */
const onConfirmChangeAssetPriceProvider = async () => {
  customBeforeChangePriceProvider.value = customAddParams.priceProviderId
}

/**
 * 取消选择所选择价格源
 */
const onCancelChangeAssetPriceProvider = () => {
  customAddParams.priceProviderId = customBeforeChangePriceProvider.value
}

/**
 * 打开添加/编辑资产弹窗
 * @param isEdit 是否为编辑资产弹窗
 * @param currentEditData 编辑资产弹窗数据
 */
const changeDialogStatus = async (isEdit: boolean = false, currentEditData = {}) => {
  editData.value = currentEditData
  isEditAssets.value = isEdit
  isAddStandAssets.value = !isEdit
  submitErrorInfo.value = null
  showAddAssetsDialog.value = !showAddAssetsDialog.value
  if (isEditAssets.value && !isEmpty(editData.value)) {
    customAddParams.assetName = editData.value.underlyingCurrency?.name || ''
    customAddParams.symbolCode = editData.value.underlyingCurrency?.showSymbol || ''
    customAddParams.logo = editData.value.underlyingCurrency.logo
    customAddParams.priceProviderId =
      editData.value.underlyingCurrency?.currencyPriceProviderMap?.priceProviderId || '0'
    customBeforeChangePriceProvider.value = customAddParams.priceProviderId
    customAddParams.contractList = editData.value?.underlyingCurrency?.formatDetailPlatForms
      ? editData.value.underlyingCurrency?.formatDetailPlatForms?.map((item: any) => ({
          platformId: item.platform.platformId,
          address: item.contract_address,
          decimal: Number(item.decimal_place) || undefined,
          errorType: -1,
          isDisabled: true
        })) || []
      : []

    const checkedPlatformIds = customAddParams.contractList.map((obj) => obj.platformId) || []
    platFormOptions.value = allChainList.value.map((item) => ({
      id: item.platformId,
      value: item.platformId,
      label: item.name,
      icon: item.logo,
      alt: item.logo || '',
      isDisabled: checkedPlatformIds.includes(item.platformId)
    }))
  }
}

/**
 * 关闭资产弹窗
 */
const onCloseDialog = () => {
  submitErrorInfo.value = null
  isAddStandAssets.value = true
  smartContractList.value = []
  currentCurrency.value = ''
  priceProviderId.value = ''
  customAddParams.assetName = ''
  customAddParams.symbolCode = ''
  customAddParams.logo = ''
  customAddParams.priceProviderId = '0'
  customBeforeChangePriceProvider.value = ''
  customAddParams.contractList = []
}

/**
 * 远程搜索平台信息
 */
const filterWantPlattList = () => {
  return async (query: string) => {
    searchLoading.value = true
    let currentList = []
    if (!query || !query.length) {
      currentList = allChainList.value
    } else {
      currentList = allChainList.value.filter((item) => item.name.includes(query))
    }
    const allSelectPlatformIds = customAddParams.contractList.map((obj) => obj.platformId) || []
    platFormOptions.value = currentList.map((item) => ({
      id: item.platformId,
      value: item.platformId,
      label: item.name,
      icon: item.logo,
      alt: item.logo || '',
      isDisabled: allSelectPlatformIds.includes(item.platformId)
    }))
    searchLoading.value = false
  }
}

/**
 * 远程搜索币种信息
 * @param query 币种名称
 */
const remoteCurrencyMethod = async (query: string) => {
  if (query) {
    searchLoading.value = true
    const params: any = {
      recommend: false,
      type: 'CRYPTO',
      entityId: 0,
      keywords: [query] || []
    }
    try {
      await getRecommendCurrency(params)
    } catch (error) {
      console.log(error)
    } finally {
      searchLoading.value = false
    }
  } else {
    if (!currenciesData.value.length) {
      searchLoading.value = true
      try {
        await getRecommendCurrency({ recommend: true, type: 'CRYPTO', entityId: 0 })
      } catch (error: any) {
        console.log(error.message)
      } finally {
        searchLoading.value = false
      }
    }
    currenciesData.value = cloneDeep(recommendCurrenciesData.value)
  }
}

/**
 * 上传资产Logo处理
 * @param files 上传文件对象
 */
const handleExceed: UploadProps['onExceed'] = (files) => {
  uploadLogoRef.value!.clearFiles()
  const file = files[0] as UploadRawFile
  file.uid = genFileId()
  uploadLogoRef.value!.handleStart(file)
  uploadLogoRef.value!.submit()
}

/**
 * 上传资产Logo前校验
 * @param rawFile 上传文件对象
 */
const beforeUploadAssetLogo: UploadProps['beforeUpload'] = (rawFile) => {
  uploadLoading.value = true
  if (rawFile.size / 1024 / 1024 > 100) {
    ElMessage.error('The file size can not exceed 100m!')
    return false
  }
  return true
}

/**
 * 上传资产Logo报错
 * @param error 上传错误信息
 */
const onUploadError: UploadProps['onError'] = (error) => {
  const err = JSON.parse(error.message)
  ElMessage.error(err?.data?.message)
  uploadLoading.value = false
}

/**
 * 上传资产Logo成功
 */
const onUploadSuccess: UploadProps['onSuccess'] = ({ data: { url } }) => {
  customAddParams.logo = url || defaultLogo
  uploadLoading.value = false
}

/**
 * 添加自定义资产，合约地址报错，显示错误合约所属资产信息
 */
const ChangeBelongAssetAddress = async () => {
  const searchCurrency = `${submitErrorInfo.value.extraData?.underlyingcurrency?.name || ''}`
  currentCurrency.value = `${submitErrorInfo.value.extraData?.underlyingcurrency?.underlyingCurrencyId || ''}`
  customAddParams.assetName = ''
  customAddParams.symbolCode = ''
  customAddParams.contractList = []
  customAddParams.logo = defaultLogo
  customAddParams.priceProviderId = '0'
  customBeforeChangePriceProvider.value = ''
  submitErrorInfo.value = null
  const hasCurrency = currenciesData.value.find((item) => item.value.includes(currentCurrency.value))
  if (hasCurrency) {
    currentCurrencyData.value = hasCurrency
    currentCurrency.value = currentCurrencyData.value?.value || ''
    smartContractList.value = hasCurrency?.formatDetailPlatformsList || []
    isAddStandAssets.value = true
    return
  }
  const params: any = {
    recommend: false,
    type: 'CRYPTO',
    entityId: 0,
    keywords: [searchCurrency.charAt(0)] || []
  }
  const searchList = await ConfigApi.searchCurrencyList(params)
  currenciesData.value = searchList.data.map((item) => ({
    id: item.underlyingCurrencyId,
    value: item.underlyingCurrencyId,
    label: item.name,
    suffixName: item.showSymbol,
    icon: item.logo,
    alt: item.logo || '',
    isCheck: false
  }))
  currentCurrencyData.value = currenciesData.value.find((item) => item.value.includes(currentCurrency.value))
  smartContractList.value = currentCurrencyData.value?.formatDetailPlatformsList || []
  currentCurrency.value = currentCurrencyData.value?.value || ''
  isAddStandAssets.value = true
}

/**
 * 添加新的合约地址
 */
const onClickAddNewContract = () => {
  customAddParams.contractList.push({
    platformId: '',
    address: '',
    decimal: undefined,
    errorType: -1
  })
  nextTick(() => {
    customContract.value.scrollTop = customContract.value.scrollHeight
  })
}

/**
 * 删除合约地址
 * @param index 合约地址在列表中的角标
 */
const onClickedDeleteContract = (index: any) => {
  const smartItem: any = customAddParams.contractList.splice(index, 1)
  if (smartItem?.length && smartItem[0]?.errorType !== -1) {
    submitErrorInfo.value = null
  }
}

/**
 * 复制合约地址
 * @param msg 复制内容
 */
const onClickedCopy = async (msg: string) => {
  try {
    await toClipboard(msg)
    ElMessage.success(t('message.copiedToClipboard'))
  } catch (e) {
    console.error(e)
  }
}

/**
 * 切换币种信息回调
 */
const onChangeCurrency = () => {
  submitErrorInfo.value = null
  const currentCurrenciesInfo = currenciesData.value?.find((item) => item.value === currentCurrency.value)
  currentCurrencyData.value = currentCurrenciesInfo
  const currentPriceProvider = priceProviderList.value.find((item) => item.alt === 'API_COINGECKO')
  if (currentPriceProvider) {
    priceProviderId.value = currentPriceProvider.value
  }
  if (currentCurrenciesInfo && currentCurrenciesInfo?.formatDetailPlatformsList) {
    const contractAddresses = Object.values(currentCurrenciesInfo?.formatDetailPlatformsList).filter(
      (filterItem: any) => filterItem.contract_address !== ''
    )
    smartContractList.value = contractAddresses
  } else {
    smartContractList.value = []
  }
}

/**
 * 选择币种选择器弹窗显示状态
 * @param showStatus 显隐状态
 */
const changeSelectPopover = (showStatus: boolean) => {
  if (!showStatus && currentCurrency.value) {
    const isHasSelectCurrency = currenciesData.value.some((item) => item.value === currentCurrency.value)
    if (!isHasSelectCurrency && currentCurrency.value && currentCurrencyData.value) {
      const info: any = cloneDeep(currentCurrencyData.value)
      currenciesData.value.unshift(info)
    }
  }
}

/**
 * 切换平台回调
 * @param item 平台数据
 * @param index 平台所属列表角标
 */
const changeSelectPlatform = (item?: any, index?: any) => {
  if (isAddStandAssets.value) {
    submitErrorInfo.value = null
    return
  }
  if (submitErrorInfo.value && submitErrorInfo.value?.extraData?.index === index) {
    submitErrorInfo.value = null
    item.errorType = -1
  }
}

/**
 * 切换到添加自定义资产界面
 */
const addCustomAssets = () => {
  submitErrorInfo.value = null
  isAddStandAssets.value = false
}

/**
 * 添加自定义资产，提交表单，合约报错，显示报错信息
 */
const changeContractErrorStatus = () => {
  if (submitErrorInfo.value && submitErrorInfo.value?.extraData) {
    customAddParams.contractList.forEach((item, index) => {
      if (index === submitErrorInfo.value.extraData.index) {
        item.errorType = submitErrorInfo.value.code
      } else {
        item.errorType = -1
      }
    })
  } else {
    customAddParams.contractList.forEach((item) => {
      item.errorType = -1
    })
  }
}

/**
 * 确认提交资产信息
 */
const onClickConfirmSaveAssetInfo = async () => {
  let addParam = {}
  submitErrorInfo.value = null
  isAddLoading.value = true
  if (isAddStandAssets.value) {
    addParam = { underlyingCurrencyId: currentCurrency.value, priceProviderId: priceProviderId.value }
  } else {
    const submitContract = customAddParams.contractList.map((delItem) => {
      return {
        address: delItem.address,
        decimal: delItem.decimal,
        platformId: delItem.platformId
      }
    })
    addParam = {
      currency: {
        name: trim(customAddParams.assetName),
        symbol: trim(customAddParams.symbolCode),
        priceProviderId: customAddParams.priceProviderId,
        logo: customAddParams.logo,
        contractList: submitContract
      }
    }
  }
  try {
    if (isEditAssets.value) {
      await ValuationApi.updateAsset(entityId.value, editData.value.assetId, addParam)
      ElMessage.success(t('message.editAssetSuccessfully'))
    } else {
      await ValuationApi.addNewAssets(entityId.value, addParam)
      ElMessage.success(t('message.addAssetSuccessfully'))
    }
    emit('onClickConfirmHandler')
  } catch (error: any) {
    if (error.code === errorCode.assetExist || error.code === errorCode.addressExist) {
      submitErrorInfo.value = error
      changeContractErrorStatus()
    } else if (error.code === errorCode.standExist) {
      submitErrorInfo.value = error
    } else {
      ElMessage.error(error.message)
    }
  } finally {
    isAddLoading.value = false
  }
}

defineExpose({
  changeDialogStatus
})
</script>

<style lang="scss">
.elv-delete-tip-add-assets-dialog {
  box-shadow:
    0px 2px 6px rgba(0, 0, 0, 0.05),
    0px 0px 1px rgba(0, 0, 0, 0.3);
  border-radius: 6px;

  .el-dialog__header {
    border-bottom: 1px solid #edf0f3;
    padding: 0px;
  }

  .elv-add-tip-assets-dialog-header-title {
    min-height: 54px;
    text-align: center;
    font-size: 16px;
    color: #0e0f11;
    line-height: 24px;
    height: 49px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 800;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 24px;
  }

  .elv-add-assets-dialog-content {
    padding: 0px 24px;
    position: relative;

    .content-title {
      font-weight: 600;
      font-size: 13px;
      color: #636b75;
      line-height: 40px;
    }

    .content-contract-content {
      max-height: 300px;
      overflow: auto;
      position: relative;

      p {
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 6px;
        border: 1px solid #dde1e6;
        border-radius: 4px;
        box-shadow: 0px 1px 3px 0px #00000014;
        padding: 0px 20px;
        position: relative;
        color: #0e0f11;
        font-size: 14px;
        background: #f9fafb;

        .contract-smart {
          width: calc(100% - 50px);
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .sources-copy {
          cursor: pointer;
        }

        img {
          border-radius: 50%;
          width: 20px;
          height: auto;
          margin-right: 10px;
        }
      }

      svg {
        position: absolute;
        right: 20px;
      }
    }

    .content-contract-content-empty {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 80px;
      color: #000;
    }

    .elv-base-extend-select {
      .elv-base-extend-select-popper {
        .el-select-dropdown__wrap {
          ul > li {
            padding: 0px 8px;
          }
        }

        .el-select-dropdown__footer {
          padding: 0px;
        }

        .add-custom-assets {
          height: 32px;
          display: flex;
          align-items: center;
          font-family: 'Plus Jakarta Sans';
          border-top: 1px solid #dde1e6;
          font-size: 14px;
          font-weight: 400;
          color: #1e2024;
          padding: 0px 8px;
          cursor: pointer;

          svg {
            transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
            margin-right: 8px;
          }

          &:hover {
            color: #1753eb;

            svg {
              fill: #1753eb;
            }
          }
        }
      }

      .elv-add-assets-dialog-content-price-provider-popper {
        ul > li {
          .suffix-name {
            display: flex;
            align-items: center;
            margin-left: 8px;
            height: 19px;
            padding: 0px 8px;
            border-radius: 14px;
            background-color: #fff9f8;
            font-size: 10px;
            color: #eb3a30;
            line-height: 12px;
            font-weight: 400;
          }
        }

        .el-select-dropdown__footer {
          padding: 0px;
        }
      }
    }

    .default-submit-icon {
      width: 70px;
      height: 70px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #f9fafb;
      border-radius: 4px;
      cursor: pointer;
    }

    .add-contract-button {
      width: 118px;
      height: 32px;
      font-size: 13px;
      color: #1753eb;
      margin-top: 8px;
      font-weight: 400;
    }

    .contract-content {
      max-height: 200px;
      overflow: auto;

      .contract-content-item {
        display: flex;
        flex-direction: column;
        margin-bottom: 8px;

        .contract-content-item-check-content {
          display: flex;
          align-items: center;

          svg {
            cursor: pointer;

            &.elv-contract-item-delete-disabled {
              cursor: none;
              pointer-events: none;
              opacity: 0.2;
            }
          }

          .contract-content-item-address {
            margin: 0 8px;
            width: 120px;

            .el-input__inner {
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }

          .contract-content-item-decimal {
            margin-right: 8px;
            width: 100px;
          }

          .el-input {
            &.is-disabled {
              pointer-events: none;
            }
          }

          .el-select__wrapper {
            &.is-disabled {
              pointer-events: none;
              box-shadow: none;
            }
          }
        }
      }
    }

    .leger-asset-add-upload-log {
      width: 70px;
      height: 70px;
      background-color: #f9fafb;
      display: flex;
      align-items: center;
      justify-content: center;

      .leger-asset-add-upload-log-icon-content {
        img {
          width: 36px;
          height: 36px;
          border-radius: 50%;
        }
      }
    }

    .elv-add-contract-waring-info {
      margin-top: 8px;
      font-size: 12px;
      color: #7e4a15;

      span {
        font-weight: 700;
      }

      .link {
        border-bottom: 1px solid #7e4a15;
        cursor: pointer;
      }
    }

    .elv-add-assets-dialog-content-price-provider-select {
      .el-select__selected-item {
        span {
          max-width: 330px !important;
        }
      }
    }
  }

  .elv-add-tip-assets-dialog-footer-content {
    min-height: 84px;
    display: flex;
    align-items: center;
    justify-content: center;

    .el-button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 44px;
      width: 113px;
      background: #1753eb;
      border-radius: 22px;
      font-family: 'Plus Jakarta Sans';
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      color: #fff;
      border: 0px;
    }

    .elv-Account-delete-dialog-footer__cancel {
      background: #edf0f3;
      color: #636b75;
      pointer-events: none;
    }

    .elv-Account-delete-dialog-footer__delete {
      margin-left: 20px;
    }
  }
}
</style>
