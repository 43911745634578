<template>
  <div class="elv-financials-valuation-assets-detail-table">
    <div class="elv-financials-valuation-assets-detail-table-container">
      <el-table
        v-loading="props.tableLoading"
        :data="props.tableData"
        row-key="key"
        border
        stripe
        height="100%"
        class="elv-valuation-assets-table"
        header-cell-class-name="elv-valuation-assets-table-header__cell"
        header-row-class-name="elv-valuation-assets-table-header"
        row-class-name="elv-valuation-assets-table-row"
        cell-class-name="elv-valuation-assets-table-row__cell"
        :tree-props="{ children: 'list' }"
        default-expand-all
        @row-click="onCellClicked"
      >
        <el-table-column width="40" align="center" type="index" class-name="elv-valuation-assets-table-row__cell-index">
          <template #default="{ $index }">
            <p class="elv-sources-table-row__cell-index">
              {{ $index + 1 + (props.pageParams.page - 1) * props.pageParams.limit }}
            </p>
          </template>
        </el-table-column>
        <el-table-column
          width="300"
          class-name="elv-valuation-assets-table-row__cell-name"
          :label="t('title.assetsName')"
        >
          <template #default="{ row }">
            <SvgIcon v-if="!itemAssetInfo(row).icon" class="empty-icon" name="submit-icon" width="18" height="18" />
            <IconWithTitleCell
              :title="itemAssetInfo(row).name || ''"
              :icon="itemAssetInfo(row).icon || ''"
              :memo="itemAssetInfo(row).symbol || ''"
            />
          </template>
        </el-table-column>
        <el-table-column
          width="200"
          class-name="elv-valuation-assets-table-row__cell-category"
          :label="t('title.assetCategory')"
        >
          <template #default="{ row }">
            <div>{{ t(currentCategory(row)) }}</div>
          </template>
        </el-table-column>
        <el-table-column
          align="left"
          class-name="elv-valuation-assets-table-row__cell-priceprovider"
          :label="t('report.priceProvider')"
        >
          <template #default="{ row }">
            <div>
              <IconWithTitleCell
                v-if="['DERIVATIVE'].includes(row.category)"
                :icon="itemAssetInfo(row).icon"
                :title="itemAssetInfo(row).title"
              />
              <IconWithTitleCell
                v-else
                :icon="itemAssetInfo(row).priceIcon"
                :title="itemAssetInfo(row).priceTitle"
                :isRectIcon="itemAssetInfo(row).isCSVFile"
              />
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <elv-pagination
      v-if="tableData.length"
      class="elv-journals-list-pagination"
      size-popper-class="elv-journals-pagination__sizes-popper"
      :limit="props.pageParams.limit"
      :current-page="props.pageParams.page"
      :total="props.pageParams.total"
      @current-change="onChangePage"
      @size-change="onChangePageSize"
    />
  </div>
</template>
<script lang="ts" setup>
import { isEmpty } from 'lodash-es'
import csvFileIcon from '@/assets/icons/csv-file.svg'
import { assetCategoryType } from '@/config/transactions'
import emptyWaringIcon from '@/assets/icons/empty-warning.svg'
import coinGeckoIcon from '@/assets/img/project/coinGeckoIcon.png'
import IconWithTitleCell from '@/components/Base/Table/IconWithTitleCell.vue'

const props = defineProps<{
  tableData: any[]
  tableLoading: boolean
  pageParams: any
}>()

const emit = defineEmits(['onChangePage', 'onChangePageSize', 'openDetailOverlay'])

const { t } = useI18n()

const currentCategory = computed(() => {
  return (row: any) => {
    const category = assetCategoryType.find((item) => item.value === row?.category)
    return category?.label || '-'
  }
})

const itemAssetInfo = computed(() => {
  return (row: any) => {
    const assetData = {
      icon: '',
      title: '-',
      name: '',
      symbol: '',
      priceIcon: emptyWaringIcon,
      priceTitle: 'Not specified',
      isCSVFile: false
    }
    if (isEmpty(row?.underlyingCurrency) && isEmpty(row?.derivative)) {
      return assetData
    }
    if (isEmpty(row?.underlyingCurrency)) {
      assetData.name = row?.derivative?.name || ''
      assetData.title = row?.derivative?.platform?.name || '-'
      assetData.icon = row?.derivative?.platform?.logo || ''
      assetData.symbol = ''
    } else {
      const priceProvider = row?.underlyingCurrency?.currencyPriceProviderMap?.priceProvider
      assetData.name = row?.underlyingCurrency?.name || ''
      assetData.title = row?.underlyingCurrency?.showSymbol || '-'
      assetData.icon = row?.underlyingCurrency?.logo || ''
      assetData.symbol = row?.underlyingCurrency?.showSymbol || ''
      if (priceProvider) {
        assetData.isCSVFile = priceProvider?.type.includes('CSV')
        assetData.priceIcon = priceProvider?.type.includes('CSV') ? csvFileIcon : coinGeckoIcon
        assetData.priceTitle = `${priceProvider?.name} ${priceProvider?.isDefault ? `(${t('common.default')})` : ''}`
      }
    }
    return assetData
  }
})

/**
 * 分页器页码变化触发
 * @param page 页码
 */
const onChangePage = (page: number) => {
  emit('onChangePage', page)
}

/**
 * 分页器每页条数变化触发
 * @param limit 每页条数
 */
const onChangePageSize = (limit: number) => {
  emit('onChangePageSize', limit)
}

/**
 * 点击单元格触发，打开资产详情界面
 * @param row 行数据
 */
const onCellClicked = (row: any) => {
  emit('openDetailOverlay', row)
}
</script>
<style lang="scss">
.elv-financials-valuation-assets-detail-table {
  width: 100%;
  height: calc(100% - 50px);
  position: relative;

  .elv-financials-valuation-assets-detail-table-container {
    width: 100%;
    height: calc(100% - 54px);
    position: relative;
  }

  .elv-valuation-assets-table {
    --el-table-border-color: #d4dce5;
    --el-table-border: 1px solid #edf0f3;
    --el-fill-color-lighter: #f9fafb;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #d4dce6;
    border-right: 0px;
    border-left: 0px;
    border-bottom-width: 1px;

    .el-table__inner-wrapper {
      width: 100%;

      &::after,
      &::before {
        width: 0;
        height: 0;
      }
    }

    &.el-table--border {
      border-bottom-color: #edf0f3;

      &::after,
      &::before {
        width: 0;
        height: 0;
      }
    }

    .el-table__body-wrapper {
      height: 616px;

      .el-scrollbar__view {
        width: 100%;
        margin-bottom: 0px !important;
      }
    }

    .el-table__border-left-patch {
      width: 0;
    }

    .elv-valuation-assets-table-header {
      background: #eef4fb;

      .elv-valuation-assets-table-header__cell {
        background: #eef4fb;
        height: 36px;
        box-sizing: border-box;
        border-right-color: #e4e7eb;
        border-bottom-color: #ced7e0;
        padding: 0px;

        &:last-child {
          border-right: 0px;
        }

        &.is-center {
          .cell {
            justify-content: center;
          }
        }

        &.is-right .cell {
          justify-content: flex-end;
        }

        .cell {
          font-family: 'Plus Jakarta Sans';
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 15px;
          color: #666d74;
          padding: 0 10px;
          display: flex;
          align-items: center;
          justify-items: center;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }

    .el-table__row.elv-valuation-assets-table-row {
      background-color: #fff;

      &.el-table__row--level-0 .elv-valuation-assets-table-row__cell,
      .elv-valuation-assets-table-row__cell {
        .cell {
          font-size: 13px;
          font-weight: 600;
          word-break: break-word;
        }

        span {
          padding-left: 10px;
        }
      }

      &.el-table__row--level-1 .elv-valuation-assets-table-row__cell {
        background-color: #ffffff;

        &.elv-valuation-assets-table-row__accountName {
          .cell {
            font-weight: 500;
          }
        }

        &.elv-valuation-assets-table-row__accountId span {
          padding-left: 30px;
        }
      }

      &.el-table__row--level-2 .elv-valuation-assets-table-row__cell {
        background-color: #f9f9f9;

        &.elv-valuation-assets-table-row__accountName {
          .cell {
            font-weight: 500;
          }
        }

        &.elv-valuation-assets-table-row__accountId span {
          padding-left: 50px;
        }
      }

      &.el-table__row--level-3 .elv-valuation-assets-table-row__cell {
        background-color: #f9f9f9;

        &.elv-valuation-assets-table-row__accountName {
          .cell {
            font-weight: 500;
          }
        }

        &.elv-valuation-assets-table-row__accountId span {
          padding-left: 70px;
        }
      }

      &.el-table__row--level-4 .elv-valuation-assets-table-row__cell {
        background-color: #f9f9f9;

        &.elv-valuation-assets-table-row__accountName {
          .cell {
            font-weight: 500;
          }
        }

        &.elv-valuation-assets-table-row__accountId span {
          padding-left: 90px;
        }
      }

      &.el-table__row--level-5 .elv-valuation-assets-table-row__cell {
        background-color: #f9f9f9;

        &.elv-valuation-assets-table-row__accountName {
          .cell {
            font-weight: 500;
          }
        }

        &.elv-valuation-assets-table-row__accountId span {
          padding-left: 110px;
        }
      }
    }

    .elv-valuation-assets-table-row__cell {
      padding: 0;
      height: 42px;
      cursor: pointer;

      &:last-child {
        border-right: 0px;
      }

      &.is-right .cell {
        text-align: right;
      }

      &.is-center .cell {
        text-align: center;
      }

      &.is-number .cell {
        font-family: 'Barlow';
        font-weight: 500;
      }

      .cell {
        padding: 0 10px;
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        color: #0e0f11;
        line-height: 16px;

        .el-table__indent {
          padding-left: 0px !important;
          border-left: 0px;
        }

        .el-table__placeholder {
          width: 40px;
          box-sizing: border-box;
          height: 100%;
          padding-left: 40px !important;
          border-left: 0px;
        }

        span {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          border-left: 1px solid #edf0f3;
          white-space: nowrap;
        }

        .el-table__expand-icon {
          width: 40px;
          height: 100%;
          display: none;
          align-items: center;
          justify-content: center;
          box-sizing: border-box;
          margin-right: 0px;
        }
      }

      &.elv-valuation-assets-table-row__cell-index .cell {
        color: #aaafb6;
        font-family: 'Barlow';
        font-size: 11px;
        font-style: normal;
      }

      &.elv-valuation-assets-table-row__cell-name .cell {
        display: flex;
        align-items: center;

        .empty-icon {
          width: 18px;
          height: 18px;
          margin-right: 8px;
        }
      }

      &.elv-valuation-assets-table-row__cell-priceprovider .cell {
        .elv-base-cell-icon-title {
          .elv-base-cell-icon-title-title {
            font-weight: 400 !important;
            font-size: 14px;
          }

          &.waring-custom-icon {
            .elv-base-cell-icon-title-title {
              color: #838d95;
            }
          }
        }
      }

      &.elv-valuation-assets-table-row__cell-category .cell {
        font-weight: 400 !important;
      }

      .elv-valuation-assets-table-row__cell-activity {
        font-weight: 500;
      }

      &.el-table-column--selection {
        .cell {
          width: 100%;
          padding: 0px;
          align-items: center;
          justify-content: center;
        }
      }

      .elv-valuation-assets-table-row__cell-desc {
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        word-break: break-word;
      }

      .elv-valuation-assets-cell__status {
        display: flex;
        height: 22px;
        width: fit-content;
        padding: 0px 8px;
        box-sizing: border-box;
        align-items: center;
        gap: 4px;
        border-radius: 14px;
        border: 1px solid #d0d4d9;
        background: #edf0f3;
        color: #636b75;

        svg {
          margin-right: 4px;
        }

        &.is-approved {
          border-color: #a9dc78;
          background: #f3fceb;
          color: #50970b;
        }
      }

      .elv-valuation-assets-table-row__cell-button {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          cursor: pointer;
          fill: #838d95;

          &.is-disabled {
            cursor: not-allowed !important;
            fill: #edf0f3 !important;
          }

          &:first-of-type {
            margin-right: 8px;
          }

          &:hover {
            fill: #1e2024;
          }
        }
      }
    }

    .el-table__footer-wrapper {
      .el-table__footer {
        height: 42px;

        .cell.elv-valuation-assets-table-row__total {
          color: #636b75;
          font-family: 'Plus Jakarta Sans';
          font-size: 11px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        .cell.elv-valuation-assets-table-row__amount {
          color: #0e0f11;
          font-family: 'Barlow';
          font-size: 13px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          text-transform: uppercase;

          p {
            color: #838d95;
            font-family: 'Barlow';
            font-size: 11px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: none;
          }
        }
      }
    }
  }

  .elv-base-cell-icon-title {
    padding: 12px 0px;
  }
}
</style>
